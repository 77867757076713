<template>
  <v-theme-provider dark>
    <v-app-bar id="home-app-bar" app elevation="1" height="80">
      <base-img class="d-none d-sm-flex" :src="require(`@/assets/logo-12kb-500x115.png`)" contain max-width="250" width="100%" />
      <base-img class="hidden-sm-and-up" :src="require(`@/assets/logo-12kb-500x115.png`)" contain max-width="150" width="100%" />
      <v-toolbar color="black" v-show="!isUserFacing">
        <v-spacer /><v-btn color="black" @click="isWebserverDialogVisible = true">{{ webServerIcon }}</v-btn>
        <v-dialog v-model="isWebserverDialogVisible" width="90%" transition="dialog-top-transition">
          <v-card align="center">
            <v-card-title class="error title">{{ webServerDisplayName }} WEB SERVER</v-card-title>
            <v-card-title class="title">TokenAuth → {{ azFunctionTokenAuthURL }}</v-card-title>
            <v-card-text v-show="isLoggedInProp && !isPageLoading" class="text-caption">{{ azFunctionTokenAuthVersion }}</v-card-text>
            <template v-if="isLoggedOutProp"><v-btn :ripple="true" class="font-weight-bold" min-width="96" @click="loginAction()">Login to see version</v-btn> </template>
            <v-card-title class="title">Public → {{ azFunctionPublicURL }}</v-card-title>
            <v-card-text class="text-caption">{{ azFunctionPublicVersion }}</v-card-text>
            <v-divider color="error" />
            <v-card-actions>
              <v-card-title class="text-body-1">⛔️ This text never appears in Production</v-card-title><v-spacer />
              <v-btn color="error" @click="isWebserverDialogVisible = false">Got it</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-toolbar color="black"
        ><v-spacer /><v-spacer />
        <template v-if="isLoggedOutProp"> <v-spacer /><v-spacer /><v-btn color="accent" :ripple="true" class="font-weight-bold" min-width="96" @click="loginAction()">Login</v-btn> </template>
      </v-toolbar>

      <div v-show="isLoggedInProp && !isPageLoading">
        <v-spacer />
        <v-toolbar color="black">
          <v-menu transition="slide-y-transition" bottom right offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-card class="mx-auto" max-width="300" tile>
              <v-list v-if="!isPaidProp && !isEmptyProp && !isNotSubmittedProp && !isSubmittedProp && !isRejectedProp && !isAcceptedProp">
                <v-list-item-group v-model="menuitemModel" color="accent">
                  <v-list-item v-for="(item, i) in menuitemsJustLogout" :key="i" router :to="item.link">
                    <v-list-item-action>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-list v-if="isPaidProp">
                <v-list-item-group v-model="menuitemModel" color="accent">
                  <v-list-item v-for="(item, i) in menuitemsPaid" :key="i" router :to="item.link">
                    <v-list-item-action>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-list v-if="isEmptyProp || isNotSubmittedProp || isSubmittedProp || isRejectedProp">
                <v-list-item-group v-model="menuitemModel" color="accent">
                  <v-list-item v-for="(item, i) in menuitemsApplication" :key="i" router :to="item.link">
                    <v-list-item-action>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-list v-if="isAcceptedProp">
                <v-list-item-group v-model="menuitemModel" color="accent">
                  <v-list-item v-for="(item, i) in menuitemsAccepted" :key="i" router :to="item.link">
                    <v-list-item-action>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </v-toolbar>
      </div>
    </v-app-bar>
  </v-theme-provider>
</template>

<script>
import axios from 'axios'
import { AuthServiceConstants, AuthServiceStage } from '@/msal/AuthServiceInterface'
import { endpoints } from '@/environments/Endpoints.ts'
import { environmentVariables } from '@/environments/EnvironmentVariables.ts'
import { EnvX, envx } from '@/environments/EnvX.ts'
import { PubSub } from '@/publishsubscribe/pub-sub'
import { RegistrationStage, AlertTimeout } from '@/RegistrationStage.ts'
import { RemoteAccess } from '@/RemoteAccess'

export default {
  name: 'Header',

  data: () => ({
    authServiceStage: AuthServiceStage.Unknown,
    azFunctionPublicURL: new URL(envx.publicFunctionsUrl).hostname.toLowerCase(),
    azFunctionPublicVersion: '',
    azFunctionTokenAuthURL: new URL(envx.tokenAuthFunctionsUrl).hostname.toLowerCase(),
    azFunctionTokenAuthVersion: '',
    isPageLoading: true,
    isUserFacing: envx.userfacing,
    isWebserverDialogVisible: false,
    menuitemModel: '',
    registrationStage: RegistrationStage.Disabled,
    webServerDisplayName: envx.webservername.toUpperCase(),
    webServerIcon: envx.webservericon,

    menuitemsJustLogout: [
      {
        text: 'Logout',
        icon: 'mdi-logout',
        link: '/logout'
      }
    ],

    menuitemsPaid: [
      {
        text: 'Home',
        icon: 'mdi-home',
        link: '/'
      },
      {
        text: 'Professional Search',
        icon: 'mdi-magnify',
        link: '/search'
      },
      {
        text: 'Invitation',
        icon: 'mdi-account-plus',
        link: '/sendinvite'
      },
      {
        text: 'My Profile',
        icon: 'mdi-account-circle',
        link: '/profile'
      },
      {
        text: 'Active Specialists',
        icon: 'mdi-web',
        link: '/network'
      },
      {
        text: 'Approvals & Stripe',
        icon: 'mdi-check-circle',
        link: '/approvals'
      },
      {
        text: 'Logout',
        icon: 'mdi-logout',
        link: '/logout'
      }
    ],

    menuitemsAccepted: [
      {
        text: 'Home',
        icon: 'mdi-home',
        link: '/'
      },
      {
        text: 'My Profile',
        icon: 'mdi-account-circle',
        link: '/profile'
      },
      {
        text: 'Pay now',
        icon: 'mdi-credit-card-outline',
        link: '/subscribesolo'
      },
      {
        text: 'Logout',
        icon: 'mdi-logout',
        link: '/logout'
      }
    ],

    menuitemsApplication: [
      {
        text: 'Home',
        icon: 'mdi-home',
        link: '/'
      },
      {
        text: 'Edit Application',
        icon: 'mdi-pencil',
        link: '/editprofile'
      },
      {
        text: 'Logout',
        icon: 'mdi-logout',
        link: '/logout'
      }
    ]
  }),

  mounted() {
    PubSub.subscribe(AuthServiceConstants.LOG_STATUS_CHANGED, this.loggedStatusChangedCallback)
    setTimeout(this.checkStatuses, this.nullTimeoutProp)
  },

  computed: {
    nullTimeoutProp() {
      return AlertTimeout.null
    },

    isLoggedInProp() {
      return this.authServiceStage === AuthServiceStage.LoggedIn && !this.isPageLoading
    },

    isLoggedOutProp() {
      return this.authServiceStage === AuthServiceStage.LoggedOut
    },

    isEmptyProp() {
      return this.registrationStage === RegistrationStage.Empty && this.isLoggedInProp
    },

    isNotSubmittedProp() {
      return this.registrationStage === RegistrationStage.NotSubmitted && this.isLoggedInProp
    },

    isRegistrationStageCompletedProp() {
      return this.registrationStage === RegistrationStage.Completed
    },

    isSubmittedProp() {
      return this.registrationStage === RegistrationStage.Submitted && this.isLoggedInProp
    },

    isAcceptedProp() {
      return this.registrationStage === RegistrationStage.Accepted && this.isLoggedInProp
    },

    isRejectedProp() {
      return this.registrationStage === RegistrationStage.Rejected && this.isLoggedInProp
    },

    isPaidProp() {
      return this.isRegistrationStageCompletedProp && this.isLoggedInProp
    }
  },

  methods: {
    checkStatuses() {
      try {
        this.isPageLoading = true
        const axiosGetPublicVersion = axios.create({
          baseURL: envx.publicFunctionsUrl,
          proxy: false
        })

        axiosGetPublicVersion
          .get(endpoints.versionPublicEndpoint, {
            params: {
              code: environmentVariables.VERSION_PUBLIC_KEY
            }
          })
          .then((response) => {
            const data = response.data
            EnvX.log('ℹ️ got public version:', data)
            this.azFunctionPublicVersion = data
          })
          .catch((error) => {
            EnvX.warn('🧨', error)
          })

        const axiosGetTokenAuthVersion = axios.create({
          baseURL: envx.tokenAuthFunctionsUrl,
          proxy: false
        })

        this.$AuthService
          .idTokenAsync('header.checkStatuses')
          .then((idToken) => {
            this.authServiceStage = AuthServiceStage.LoggedIn

            axiosGetTokenAuthVersion.interceptors.request.use(
              (config) => {
                config.headers.Authorization = `Bearer ${idToken}`
                return config
              },
              (error) => error
            )
            axiosGetTokenAuthVersion
              .get(endpoints.versionTokenAuthEndpoint, {
                params: {
                  code: environmentVariables.VERSION_TOKEN_AUTH_KEY
                }
              })
              .then((response) => {
                const data = response.data
                EnvX.log('ℹ️ got token auth version:', data)
                this.azFunctionTokenAuthVersion = data
              })
              .catch((error) => {
                EnvX.warn('🧨', error)
              })

            this.fetchSubscriptionStatus()
            this.fetchRegistrationStage()
          })
          .catch((error) => {
            if (AuthServiceConstants.LOGGED_OUT === error.message) {
              this.authServiceStage = AuthServiceStage.LoggedOut
            } else {
              this.authServiceStage = AuthServiceStage.Unknown
              EnvX.warn('🧨', error)
            }
          })
      } catch (exception) {
        this.authServiceStage = AuthServiceStage.Unknown
        EnvX.error('💥', exception)
      }
    },

    fetchRegistrationStage() {
      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '🎽 Header.fetchRegistrationStage', // log hint
        endpoints.myprofileEndpoint, // endpoint
        environmentVariables.MY_PROFILE_KEY, // code
        {}, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.isPageLoading = true
          this.showGenericFailure = false
          this.genericFailureErrorMessage = ''

          return true
        },

        // onSuccess
        (data) => {
          this.isPageLoading = false
          this.registrationStage = data.profile.registrationstage
        },

        // All sort of errors
        (error, _unusedDescription) => {
          this.isPageLoading = false
          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.authServiceStage = AuthServiceStage.LoggedOut
          }
        }
      )
    },

    fetchSubscriptionStatus() {
      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '🎽 Header.fetchSubscriptionStatus', // log hint
        endpoints.subscriptionStatusEndpoint, // endpoint
        environmentVariables.SUBSCRIPTION_STATUS_KEY, // code
        {}, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.isPageLoading = true
          this.showGenericFailure = false
          this.genericFailureErrorMessage = ''

          return true
        },

        // onSuccess
        (_) => {
          this.isPageLoading = false
        },

        // All sort of errors
        (error, _unusedDescription) => {
          this.isPageLoading = false
          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.authServiceStage = AuthServiceStage.LoggedOut
          }
        }
      )
    },

    loggedStatusChangedCallback(authServiceIsLoggedIn) {
      EnvX.log(`Header 🔔 loggedStatusChangedCallback: authServiceIsLoggedIn=${authServiceIsLoggedIn ? '👍' : '👎'}`)
      this.authServiceStage = authServiceIsLoggedIn ? AuthServiceStage.LoggedIn : AuthServiceStage.LoggedOut
    },

    loginAction() {
      this.$router.push({
        name: 'login', // push route name so that we can add params
        params: {
          successRoute: 'profileroute',
          failureRoute: 'homeroute'
        }
      })
    },

    logoutAction() {
      this.$router.push({
        name: 'logout', // push route name so that we can add params
        params: {
          successRoute: 'homeroute'
        }
      })
      // this.$AuthService.forceLogoutPopup() // test
    }
  }
}
</script>

<style>
#home-app-bar {
  background: #000;
}
</style>
